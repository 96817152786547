<template>
  <v-dialog v-model="newDialog" persistent max-width="400">
    <v-card>
      <v-card-title>subscriber type</v-card-title>
      <v-card-text>
        <v-text-field
          label="name"
          dense
          v-model="view.name"
          @input="$v.view.name.$touch()"
        ></v-text-field>
        <v-text-field
          dense
          label="shortcut"
          v-model="view.shortcut"
          @input="$v.view.shortcut.$touch()"
          :counter="7"
        ></v-text-field>
        <v-text-field
          dense
          label="description"
          v-model="view.description"
          @input="$v.view.description.$touch()"
          :counter="45"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
    myCatId: { type: Number, default: 0 },
  },
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        name: null,
        description: null,
        measurements_categoryid: this.myCatId,
        shortcut: null,
      },
    };
  },
  validations: {
    view: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {
        required,
        maxLength: maxLength(45),
      },
      shortcut: {
        required,
        maxLength: maxLength(7),
      },
    },
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let payload = { state: false, data: this.view };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
